import {
  Container,
  Button,
  CardGroup,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
} from "reactstrap";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <h2>Home</h2>
      <h5 className="mb-4">
        Welcome to A2APay Go! To get started please login using the same details
        you use for the A2APay dashboard. If you have any questions or you need
        to reset your password please speak to a member of the EchoPay team at{" "}
        <a href="mailto:support@echopay.co.uk">support@echopay.co.uk</a>.
      </h5>
      <div className="d-flex justify-content-center">
        <CardGroup>
          <Card>
            <CardImg
              alt="A2APay QR Code"
              src="https://bucket.mlcdn.com/a/2865/2865955/images/12d264c2106de345dcc6ec4acbeddf7905becd6f.png"
              top
              width="100%"
            />
            <CardBody>
              <CardTitle tag="h5">QR Codes</CardTitle>
              <CardSubtitle className="mb-2 text-muted" tag="h6">
                Easily Create Payment QR Codes
              </CardSubtitle>
              <CardText>
                Enter the customers details and a value to generate a QR Code.
              </CardText>
              <Button
                onClick={() => navigate("/create-qr-code")}
                color="primary"
              >
                QR Codes
              </Button>
            </CardBody>
          </Card>
          <Card>
            <CardImg
              alt="QR Codes"
              src="https://bucket.mlcdn.com/a/2865/2865955/images/44e3e6484d2c199c31fdeb74d80837668854a829.png"
              top
              width="100%"
            />
            <CardBody>
              <CardTitle tag="h5">Previous Payments</CardTitle>
              <CardSubtitle className="mb-2 text-muted" tag="h6">
                View your previous payments from your phone
              </CardSubtitle>
              <CardText>
                Filter by date and status to view and download your previous
                payments.
              </CardText>
              <Button
                onClick={() => navigate("/previous-payments")}
                color="primary"
              >
                Previous Payments
              </Button>
            </CardBody>
          </Card>
          <Card>
            <CardImg
              alt="A2APay Dashboard"
              src="https://bucket.mlcdn.com/a/2865/2865955/images/e4c9ad2a0f4484507f4f7c863923fa19432cfa01.png"
              top
              width="100%"
            />
            <CardBody>
              <CardTitle tag="h5">Dashboard</CardTitle>
              <CardSubtitle className="mb-2 text-muted" tag="h6">
                Integrated with the A2APay Dashboard
              </CardSubtitle>
              <CardText>
                You can view your A2APay Go payments in your dashboard.
              </CardText>
              <Button
                onClick={() =>
                  window.open("https://a2apay.co.uk/dashboard/home")
                }
                color="primary"
              >
                Dashboard
              </Button>
            </CardBody>
          </Card>
        </CardGroup>
      </div>
    </Container>
  );
};

export default Home;
