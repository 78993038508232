import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "./context/ProtectedRoute";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";
import MainNavbar from "./components/nav/Navbar";

// New
import Login from "./pages/login/Login";
import Home from "./pages/home/Home";
import PreviousPayments from "./pages/previousPayments/PreviousPayments";
import CreateQRCode from "./pages/qr/CreateQRCode";

function App() {
  return (
    <>
      <MainNavbar />
      <Routes>
        <Route
          path="/create-qr-code"
          element={
            <ProtectedRoute>
              <CreateQRCode />
            </ProtectedRoute>
          }
        />
        <Route
          path="/previous-payments"
          element={
            <ProtectedRoute>
              <PreviousPayments />
            </ProtectedRoute>
          }
        />
        <Route path="/" element={<Home />} />

        <Route path="/login" element={<Login />} />
        <Route path="/*" element={<h1>Not Found</h1>} />
      </Routes>
    </>
  );
}

export default App;
