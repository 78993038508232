export function formCheck(formData) {
  const isRefOk = checkReference(formData.reference);
  if (!isRefOk) return;
  console.log("Reference Good");
  const isAmountOk = checkAmount(formData.amount);
  if (!isAmountOk) return;
  return true;
}

function checkReference(reference) {
  if (!reference) {
    alert("Please add a reference.");
    return;
  }
  if (reference.length === 0) {
    alert("Please add a reference.");
    return;
  }
  if (reference.length > 14) {
    alert("Reference can be no more than 14 characters long.");
    return null;
  }
  if (/[^a-zA-Z0-9 ]/.test(reference)) {
    alert(
      "Reference can not contain any special charcters, please delete them and try again."
    );
    return null;
  }
  return true;
}

function checkAmount(amount) {
  if (!amount) {
    alert("You must enter an amount.");
    return;
  }
  if (amount < 0.01) {
    alert("Please enter an amount above £0.01");
    return;
  }
  if (isNaN(amount)) {
    alert("Amount must be a valid number. Please try again.");
    return;
  }
  return true;
}
