import { useState, useEffect } from "react";
import {
  Button,
  Spinner,
  Modal,
  ModalBody,
  ModalHeader,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";

export const PageTitle = () => {
  const [open, setOpen] = useState("");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };
  return (
    <>
      <Accordion open={open} toggle={toggle} className="mb-3">
        <AccordionItem>
          <AccordionHeader targetId="1">
            <h2 className="mb-0">Create New QR Code</h2>
          </AccordionHeader>
          <AccordionBody className="p-0 m-0" accordionId="1">
            Enter the customer details below to generate a new payment QR Code.
            Please note that the QR code is live for 10 minutes before it
            expires. If the customer has scanned the QR code and the payment has
            failed you will need to generate a new QR code. Please wait for the
            customer to complete the payment before checking it's status. If you
            lose connection or the status cannot be found please view the
            previous payments page.
          </AccordionBody>
        </AccordionItem>
      </Accordion>
    </>
  );
};

export const CheckStatusButton = ({ requestId, getStatus }) => {
  const [loading, setLoading] = useState();
  async function onClick() {
    setLoading(true);
    await getStatus();
    setLoading(false);
  }
  return (
    <Button
      className="w-100"
      disabled={!requestId}
      color="primary"
      onClick={onClick}
    >
      {loading ? <Spinner size="sm">Loading</Spinner> : "Check Status"}
    </Button>
  );
};

export const StatusModal = ({ toggle, modal, data }) => {
  const [statusColor, setStatusColor] = useState("text-dark");

  useEffect(() => {
    if (!data) {
      return;
    }
    if (data.status === "SUCCESS") setStatusColor("text-success");
  }, [data]);

  if (!data) {
    return;
  }

  const { status, timeStamp, statusReasonInformation, amount, reference } =
    data;

  const date = timeStamp.toDate().toLocaleString();
  const info = statusReasonInformation;

  if (!status) {
    return;
  }

  return (
    <Modal centered={true} isOpen={modal} toggle={toggle}>
      <ModalHeader
        toggle={toggle}
        className={`${statusColor} font-weight-bold text-uppercase`}
      >
        {status}
      </ModalHeader>
      <ModalBody>
        {status === "SUCCESS" ? (
          <>
            <h5>Date</h5>
            <p>{date}</p>
            <h5>Amount</h5>
            <p>£{amount}</p>
            <h5>Reference</h5>
            <p>{reference}</p>
          </>
        ) : (
          <>
            <h5>Info</h5>
            <p>{info}</p>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};
