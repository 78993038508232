import { Collapse, Nav, NavItem, Button } from "reactstrap";
import { useNavigate } from "react-router-dom";

export const NoAuth = ({ collapsed, setCollapsed }) => {
  const navigate = useNavigate();

  return (
    <Collapse isOpen={!collapsed} navbar className="text-end p-2">
      <Nav navbar>
        <NavItem className="mb-1">
          <p
            className="text-light m-0 pointer"
            onClick={() => {
              navigate("/");
              setCollapsed(!collapsed);
            }}
          >
            Home
          </p>
        </NavItem>
        <NavItem className="mb-2">
          <a
            className="text-light m-0 pointer text-decoration-none"
            href="mailto:support@echopay.co.uk"
          >
            Contact Us
          </a>
        </NavItem>
        <NavItem className="mb-1">
          <Button
            onClick={() => {
              navigate("/login");
              setCollapsed(!collapsed);
            }}
            color="success"
          >
            Sign In
          </Button>
        </NavItem>
      </Nav>
    </Collapse>
  );
};
