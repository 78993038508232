import { useState } from "react";
import { Container, Input, Button } from "reactstrap";
import { UserAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const { signIn } = UserAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  async function pressSignIn() {
    const signedIn = await signIn(email, password);
    if (signedIn) navigate("/create-qr-code");
  }
  return (
    <Container>
      <h2>Welcome!</h2>
      <h5>
        Please sign in using the same details that you use to access the A2APay
        dashboard.
      </h5>
      <Input
        type="email"
        placeholder="Email"
        valid={false}
        invalid={false}
        onChange={(e) => setEmail(e.target.value)}
        className="mb-3"
      />
      <Input
        type="password"
        placeholder="Password"
        valid={false}
        invalid={false}
        onChange={(e) => setPassword(e.target.value)}
        className="mb-3"
      />
      <Button className="w-100 mb-3" color="success" onClick={pressSignIn}>
        Login
      </Button>
      <p>
        If you need to reset your password please{" "}
        <a href="mailto:support@echopay.co.uk">email the EchoPay team.</a>
      </p>
    </Container>
  );
};

export default Login;
