import React, { useState } from "react";
import { Navbar, NavbarToggler, NavbarBrand } from "reactstrap";
import { UserAuth } from "../../context/AuthContext";
import { Auth } from "./Auth";
import { NoAuth } from "./NoAuth";
import logo from "../../assets/a2a---logo.png";

function MainNavbar(props) {
  const { user, logout } = UserAuth();
  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => setCollapsed(!collapsed);

  return (
    <div>
      <Navbar color="dark" dark className="mb-4">
        <NavbarBrand className="me-auto">
          <img src={logo} className="logo" alt="A2APay" />
        </NavbarBrand>
        <NavbarToggler onClick={toggleNavbar} className="me-2" />
        {user ? (
          <Auth
            collapsed={collapsed}
            setCollapsed={setCollapsed}
            logout={logout}
          />
        ) : (
          <NoAuth
            collapsed={collapsed}
            setCollapsed={setCollapsed}
            logout={logout}
          />
        )}
      </Navbar>
    </div>
  );
}

export default MainNavbar;
