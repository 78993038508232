import { Navigate } from "react-router-dom";
import { UserAuth } from "./AuthContext";

const ProtectedRoute = ({ children }) => {
  const { user } = UserAuth();

  if (user) {
    console.log("User Logged in: " + user.email);
  } else {
    console.log("Not Logged in.");
  }

  if (!user) {
    return <Navigate to={"/"} />;
  }
  return children;
};

export default ProtectedRoute;
