import QRCode from "react-qr-code";

export const QrCode = ({ authUrl }) => {
  if (authUrl) {
    return (
      <QRCode
        className="rounded mb-4"
        value={authUrl}
        viewBox={`0 0 256 256`}
        size={250}
      />
    );
  } else {
    return (
      <div className="mb-4">
        <div className="qr_code_blank">
          <p className="m-0">Set QR Code</p>
        </div>
      </div>
    );
  }
};
