import { useState } from "react";
import { Form, Button, Spinner, Container, Card } from "reactstrap";
import { AmountInput, ReferenceInput } from "./components/Inputs";
import { QrCode } from "./components/QrCode";
import {
  PageTitle,
  CheckStatusButton,
  StatusModal,
} from "./components/Structure";
import { getTokenRequestId } from "../../firebase/functions";
import { formCheck } from "./components/formCheck";
import { UserAuth } from "../../context/AuthContext";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import "../styles.css";

const CreateQRCode = () => {
  const { user } = UserAuth();
  const [loading, setLoading] = useState(false);
  const [qrCodeDetails, setQRCodeDetails] = useState({ uid: user.uid });
  const [authUrl, setAuthUrl] = useState("");
  const [requestId, setRequestId] = useState("");
  const [paymentDetails, setPaymentDetails] = useState();
  const [modal, setModal] = useState(false);

  const modalToggle = () => setModal((prev) => !prev);

  function onInputChange(e) {
    setQRCodeDetails({ ...qrCodeDetails, [e.target.name]: e.target.value });
  }

  function resetQrCode() {
    setRequestId("");
    setQRCodeDetails({});
    document.getElementById("qrForm").reset();
  }

  async function createNewQrCode() {
    setLoading(true);
    const isDataOk = formCheck(qrCodeDetails);
    if (!isDataOk) {
      setLoading(false);
      return;
    }

    const [token, tokenError] = await getTokenRequestId(qrCodeDetails);

    if (tokenError) {
      console.error(tokenError);
      setLoading(false);
      return;
    }

    if (token.url) {
      setAuthUrl(token.url);
      const id = token.url.split("request-token/")[1];
      setRequestId(id);
    }
    setLoading(false);
  }

  async function getStatus() {
    if (!requestId) {
      return;
    }

    const docRef = doc(db, "links", requestId);

    try {
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setPaymentDetails(docSnap.data());
        setModal(true);
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
      setLoading(false);
    } catch (error) {
      alert(
        "Payment Not Found. Please wait for payment to process with status: SUCCESS"
      );
      setLoading(false);
    }
  }

  return (
    <Container>
      <PageTitle />

      <Card className="p-3 align-items-center mb-3">
        <QrCode authUrl={authUrl} />
        <CheckStatusButton requestId={requestId} getStatus={getStatus} />
        <StatusModal toggle={modalToggle} modal={modal} data={paymentDetails} />
      </Card>

      <Form id="qrForm">
        <ReferenceInput onChange={onInputChange} />
        <AmountInput onChange={onInputChange} />
      </Form>

      <Button className="w-100 mb-3" color="success" onClick={createNewQrCode}>
        {loading ? <Spinner size="sm">Loading</Spinner> : "Create"}
      </Button>

      <Button className="w-100 mb-3" color="dark" onClick={resetQrCode}>
        Reset
      </Button>
    </Container>
  );
};

export default CreateQRCode;
