import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase";

export async function getTokenRequestId(formData) {
  const { uid, reference, amount } = formData;
  const cloudFunction = httpsCallable(functions, "pis-tokenrequest");
  try {
    const request = await cloudFunction({
      uid,
      reference,
      amount,
    });
    console.log("request", request);
    return [request.data, null];
  } catch (error) {
    alert(error.message);
    return [null, error];
  }
}

export async function getPreviousPayments(start, end, limit, setStatus) {
  const cloudFunction = httpsCallable(functions, "a2a-previouspayments");
  try {
    const request = await cloudFunction({
      startDate: start,
      endDate: end,
      limit: limit,
      setStatus: setStatus,
    });
    console.log("request", request);
    return [request.data, null];
  } catch (error) {
    alert(error.message);
    return [null, error.message];
  }
}
