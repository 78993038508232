import { useState } from "react";
import {
  Container,
  Label,
  Input,
  Button,
  Table,
  FormGroup,
  Collapse,
  Spinner,
} from "reactstrap";
import { getPreviousPayments } from "../../firebase/functions";
import CsvDownloadButton from "react-json-to-csv";

const PreviousPayments = () => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [numberOfPayments, setNumberOfPayments] = useState(10);
  const [showSuccess, setShowSuccess] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const [payments, setPayments] = useState([
    { id: "default", reference: "-", description: "-", amount: "-" },
  ]);

  async function todaysPayments() {
    setLoading(true);
    const today = await new Date();
    const [paymentArr, error] = await getPreviousPayments(
      today.setUTCHours(0, 0, 0, 0),
      today.setUTCHours(0, 0, 0, 0),
      numberOfPayments,
      showSuccess
    );
    if (error) {
      setLoading(false);
      return;
    }
    setPayments(paymentArr);
    setLoading(false);
  }

  async function getPayments() {
    setLoading(true);
    const check = dateChecker(startDate, endDate);
    if (!check) {
      setLoading(false);
      return;
    }
    const [paymentArr, error] = await getPreviousPayments(
      startDate,
      endDate,
      numberOfPayments,
      showSuccess
    );

    if (error) {
      setLoading(false);
      return;
    }

    setPayments(paymentArr);
    setLoading(false);
  }

  return (
    <Container>
      <h2>Previous Payments</h2>
      <h6 className="mb-3">
        Please select a start and end date to view your previous payments.
      </h6>
      <div className="d-flex flex-direction-row align-items-center">
        <Button color="primary" onClick={toggle} className="mb-4 me-2">
          More Options
        </Button>
        <Button
          className="w-90 mb-4 me-2"
          color="success"
          onClick={todaysPayments}
        >
          Search Today
        </Button>
        {loading && <Spinner className="mb-4 ms-4">Loading</Spinner>}
      </div>

      <Collapse isOpen={isOpen}>
        <Label for="startDate">Start Date</Label>
        <Input
          id="startDate"
          name="startDate"
          placeholder="Start Date"
          type="date"
          onChange={(e) => setStartDate(e.target.valueAsNumber)}
          className="mb-4"
        />

        <Label for="endDate">End Date</Label>
        <Input
          id="endData"
          name="endDate"
          placeholder="End Date"
          type="date"
          onChange={(e) => setEndDate(e.target.valueAsNumber)}
          className="mb-4"
        />

        <Label for="numberOfPayments">
          Number of Payments to show: {numberOfPayments}
        </Label>

        <Input
          id="numberOfPayments"
          name="numberOfPayments"
          type="range"
          min={1}
          max={25}
          defaultValue={10}
          onChange={(e) => setNumberOfPayments(e.target.valueAsNumber)}
          className="mb-4"
        />

        <Label for="numberOfPayments">Only show payment status: SUCCESS</Label>
        <FormGroup switch className="mb-4">
          <Input
            type="switch"
            role="switch"
            checked={showSuccess}
            onChange={() => {
              setShowSuccess(!showSuccess);
            }}
          />
        </FormGroup>

        <Button className="w-100 mb-4" color="success" onClick={getPayments}>
          Search
        </Button>
      </Collapse>

      <h2>Payment Table</h2>
      <h6 className="mb-3">
        If the background colour of the table row is green this means that the
        payment has been successful. If you have any questions about a certain
        payment please email us and include the reference so we can locate the
        payment quickly.
      </h6>

      <Table responsive>
        <thead>
          <tr className="table-dark">
            <th>Date & Time</th>
            <th>Description</th>
            <th>Amount</th>
            <th>Payment ID</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {payments.map((payment) => {
            let colorClass;
            if (payment.status === "SUCCESS") {
              colorClass = "table-success";
            } else if (!payment.status) {
              colorClass = "table-light";
            } else {
              colorClass = "table-danger";
            }
            return (
              <tr key={payment.reference} className={colorClass}>
                <th scope="row">{payment.timeStamp}</th>
                <td>{payment.description}</td>
                <td>£{payment.amount}</td>
                <td>{payment.reference}</td>
                <td>{payment.status}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      {payments && (
        <CsvDownloadButton className="btn btn-dark mb-4" data={payments} />
      )}
    </Container>
  );
};

function dateChecker(start, end) {
  if (!start) {
    alert("Please enter a start date.");
    return null;
  }
  if (!end) {
    alert("Please enter an end date.");
    return null;
  }
  if (start > end) {
    alert("The start date must be before the end date.");
    return null;
  }
  return true;
}

export default PreviousPayments;
