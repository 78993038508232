import { InputGroup, InputGroupText, Input } from "reactstrap";

export const ReferenceInput = ({ onChange }) => {
  return (
    <Input
      type="text"
      placeholder="Reference"
      name="reference"
      onChange={onChange}
      className="mb-3"
      maxLength="14"
    />
  );
};

export const AmountInput = ({ onChange }) => {
  return (
    <InputGroup className="mb-3">
      <InputGroupText>£</InputGroupText>
      <Input
        type="text"
        placeholder="Amount"
        inputMode="decimal"
        name="amount"
        onChange={onChange}
      />
    </InputGroup>
  );
};
