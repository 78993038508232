import { Collapse, Nav, NavItem, Button } from "reactstrap";
import { useNavigate } from "react-router-dom";

export const Auth = ({ collapsed, setCollapsed, logout }) => {
  const navigate = useNavigate();

  function logOutUser() {
    logout();
    navigate("/login");
    setCollapsed(!collapsed);
  }
  return (
    <Collapse isOpen={!collapsed} navbar className="text-end p-2">
      <Nav navbar className="pt-2">
        <NavItem className="mb-2">
          <p
            className="text-light m-0 pointer"
            onClick={() => {
              navigate("/create-qr-code");
              setCollapsed(!collapsed);
            }}
          >
            Create QR Code
          </p>
        </NavItem>
        <NavItem className="mb-2">
          <p
            className="text-light m-0 pointer"
            onClick={() => {
              navigate("/previous-payments");
              setCollapsed(!collapsed);
            }}
          >
            Previous Payments
          </p>
        </NavItem>

        <NavItem className="my-2">
          <Button onClick={logOutUser} color="danger">
            Sign Out
          </Button>
        </NavItem>
      </Nav>
    </Collapse>
  );
};
